<template>
  <div>
    <GenericTable
      ref="lista"
      name="material"
      title="Materiais"
      add-route="materialTemporarioAdicionar"
      edit-route="materialTemporarioEditar"
      rastreabilidadeRoute="materialRastrear"
      :cols="[
        'ID Temporário',
        'Material',
        'Tipo de Material',
        'Fornecedor',
        'Aviso',
        'Data',
      ]"
      :cols-name="[
        'id_material',
        'descricao',
        'tipo_material',
        'fornecedor',
        'aviso',
        'createdAt',
      ]"
      :cols-map="
        (i) => [
          i.shownId,
          i.descricao,
          i.tipoMaterial.nome,
          i.fornecedor.nome,
          i.aviso,
          moment(i.createdAt).format('DD/MM/YY HH:mm'),
        ]
      "
      route="material/temporarios"
      :permissionsToWrite="['rw_cadastro_rapido']"
      :filters="filters"
      @clear-filters="clearFilters"
      idKey="id_material"
      hasPagination
      :noadd="noAdd"
      :disabledInputs="disabledInputs"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="Descrição">
              <b-form-input
                v-model="filters.descricao"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_descricao"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="QR Code">
              <b-form-input
                class="invision-input sm"
                v-model="filters.code"
                autocomplete="off"
                ref="filter_code"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="ID">
              <b-form-input
                v-model="filters.id"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_id_material"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="Fornecedor">
              <GenericSelect
                name="fornecedor"
                labelKey="nome"
                route="fornecedor"
                v-model="filters.id_fornecedor"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="Aviso">
              <b-form-input
                size="sm"
                v-model="filters.aviso"
                autocomplete="off"
                class="invision-input sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="Período">
              <RangeDatePicker
                :initDate="filters.initDate"
                :endDate="filters.endDate"
                @selected="selectedDateInDatePicker"
                position="top-left"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
    <Modal
      id="modalEtiquetaMaterial"
      ref="modalEtiquetaMaterial"
      name="modalEtiquetaMaterial"
      key="modalEtiquetaMaterial"
      hide-footer
      no-close-on-backdrop
      @hidden="closingModal"
      :hideXClose="handlerXButton"
    >
      <Printer
        :resultData="itensToPrint"
        buttonName="IMPRIMIR"
        hideSection="section"
        hideContent="content"
        :showCalibrateButton="true"
        @successSend="successZPL"
        @wants-exit="wantsExit"
        @show-xclose="handleXButton"
        :indexMaterial="indexMaterial"
      >
        <template #printContent class="w">
          <!-- TODO Hardcoded tipo material -->
          <EtiquetaOpmeConsigProcedimento
            :relatorioData="itensToPrint"
            :indexMaterial="indexMaterial"
          />
        </template>

      </Printer>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import genericRequest from '@/services/genericRequest';
import GenericSelect from '@/components/Form/GenericSelect';
import Modal from '@/components/Utils/Modal';
import Printer from '@/components/Utils/Printer';
import RangeDatePicker from '@/components/Form/RangeDatePicker';
import unidadeServices from '../../services/unidade';
import loginServices from '../../services/login';
import EtiquetaOpmeConsigProcedimento from './EtiquetaOpmeConsigProcedimento';

export default {
  components: {
    GenericSelect,
    RangeDatePicker,
    Modal,
    Printer,
    EtiquetaOpmeConsigProcedimento,
  },
  data() {
    return {
      filters: {
        descricao: '',
        code: '',
        id: '',
        initDate: null,
        endDate: null,
        id_fornecedor: null,
        aviso: '',
      },
      optionsUnidade: [
        { value: -1, text: 'Rede' },
      ],
      hasBlocoOrCmeOrOpme: false,
      noAdd: false,
      itensToPrint: {},
      indexMaterial: 0,
      zplSuccessPrinter: false,
      exit: false,
      handlerXButton: true,
      disabledInputs: [],
    };
  },
  computed: {
    ...mapState(['previousRoute', 'filtersStored', 'currentUnidade']),
  },
  async created() {
    const user = loginServices.getUser();
    this.hasBlocoOrCmeOrOpme = user.hasBloco || user.hasCme || user.areas.includes('opme');

    if (!this.hasBlocoOrCmeOrOpme) {
      this.noAdd = true;
    }

    if (this.filtersStored.material) {
      if ([
        'materialRastrear', 'materialEditar', 'materialMedicoEditar',
      ].includes(this.previousRoute.name)) {
        this.filters = { ...this.filtersStored.material };
      } else {
        this.$store.commit('setFiltersStored', {});
      }
    }

    const unidades = await unidadeServices.getUnidades();
    this.optionsUnidade = this.optionsUnidade.concat(unidades.map((unid) => ({
      value: unid.id_unidade,
      text: unid.nome,
    })));
  },
  async mounted() {
    this.setDisabledInputs();
    if (this.$route.query && this.$route.query.cadastroEmLote) {
      this.itensToPrint = await genericRequest.getWithoutPagination(
        {},
        `material/materiaisPorLote/${this.$route.query.id_lote_material}`,
      );

      this.$refs.modalEtiquetaMaterial.show();
    }
  },
  methods: {
    moment,
    selectedDateInDatePicker(initDate, endDate) {
      this.filters.initDate = initDate;
      this.filters.endDate = endDate;
    },
    setDisabledInputs() {
      const filterInputKeys = Object.keys(this.$refs).filter((key) => key.includes('filter_'));
      if (filterInputKeys) {
        const refs = this.$refs;
        const filterInputs = filterInputKeys.map((key) => {
          const input = refs[key];
          return { name: key.substr(7), input };
        });
        const disabledInputs = filterInputs.map((element) => element.input.disabled && element)
          .filter((input) => typeof input !== 'boolean');
        this.disabledInputs = disabledInputs;
        return disabledInputs;
      }
      this.disabledInputs = [];
      return [];
    },

    clearFilters() {
      this.filters.descricao = '';
      this.filters.code = '';
      this.filters.id = '';
      this.filters.initDate = null;
      this.filters.endDate = null;
      this.filters.id_fornecedor = null;
      this.filters.aviso = '';
    },
    successZPL() {
      this.zplSuccessPrinter = true;
      this.indexMaterial = 0;
      this.$refs.modalEtiquetaMaterial.hide();
    },
    closingModal() {
      if (
        this.itensToPrint
        && this.itensToPrint.materiais
        && this.itensToPrint.materiais[this.indexMaterial + 1]
        && !this.zplSuccessPrinter
        && !this.exit
      ) {
        this.indexMaterial++;
        this.$refs.modalEtiquetaMaterial.show();
      } else {
        this.exit = false;
        this.indexMaterial = 0;
        this.zplSuccessPrinter = false;
        this.$refs.modalEtiquetaMaterial.hide();
        this.$router.replace({
          name: 'materialTemporario',
        });
        this.handlerXButton = true;
      }
    },
    wantsExit() {
      this.exit = true;
      this.$refs.modalEtiquetaMaterial.hide();
    },
    handleXButton() {
      this.handlerXButton = !this.handlerXButton;
    },
  },
};
</script>
